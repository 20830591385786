import { FC, useRef, useState } from 'react';
import EmailInput from 'components/digifi-wrappers/EmailInput';
import PhoneNumberInput from 'components/digifi-wrappers/PhoneNumberInput';
import PasswordInput from 'components/digifi-wrappers/PasswordInput';
import { useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { validateEmail, validatePassword, validatePhoneNumber } from 'utils/validation';
import getMessage, { MessageType } from 'constants/Messages';
import BorrowerNamePerson from 'components/Auth/forms/BorrowerNamePerson';
import BorrowerNameCompany from 'components/Auth/forms/BorrowerNameCompany';
import { CreateAccountFormType } from 'types';
import SubmitButton from 'components/common/SubmitButton';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import { CountryCode } from 'libphonenumber-js';
import BorrowerTypeToggleGroupWrapper from 'components/digifi-wrappers/BorrowerTypeToggleGroup/BorrowerTypeToggleGroup';
import styles from './CreateAccountForm.module.scss';

interface ICreateAccountFormProps {
  handleSubmit: (values: { borrowerType: BorrowerType } & CreateAccountFormType) => Promise<void>;
  isLoading?: boolean;
}

enum CreateAccountFormBorrowerType {
  Person = 'person',
  Company = 'company',
  All = 'all',
}

const getCreateAccountFormType = (borrowerTypes: BorrowerType[]) => {
  // borrowerTypes values: ['person'], ['company'], ['person', 'company']

  if (borrowerTypes.length === 2) {
    return CreateAccountFormBorrowerType.All;
  }

  if (borrowerTypes[0] === BorrowerType.Person) {
    return CreateAccountFormBorrowerType.Person;
  }

  return CreateAccountFormBorrowerType.Company;
};

const CreateAccountForm: FC<ICreateAccountFormProps> = ({ handleSubmit, isLoading }) => {
  const availableBorrowerTypes = useAppSelector((state) => state.settings.borrowerTypes);
  const { phoneNumberFormat } = useAppSelector((state) => state.settings.variablesSettings);
  const { inviteData } = useAppSelector(state => state.auth);

  const createAccountFormType = inviteData
    ? getCreateAccountFormType([inviteData.borrowerType])
    : getCreateAccountFormType(availableBorrowerTypes);

  const [selectedBorrowerType, setSelectedBorrowerType] = useState<BorrowerType>(
    inviteData ? inviteData.borrowerType : availableBorrowerTypes[0],
  );

  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { values, errors, handleChange, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: {
      firstName: inviteData?.firstName || '',
      lastName: inviteData?.lastName || '',
      companyName: inviteData?.companyName || '',
      email: inviteData?.email || '',
      phoneNumber: inviteData?.phone || '',
      password: '',
    },
    onSubmit: (submitValues) => handleSubmit({ ...submitValues, borrowerType: selectedBorrowerType }),
    validateOnChange: false,
    enableReinitialize: true,
  });

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleBlur = (variable: string, messageType: MessageType, value: string) => {
    const trimmedValue = value.trim();

    setFieldValue(variable, trimmedValue);
    setFieldError(variable as string, trimmedValue.length > 0 ? '' : getMessage(messageType));
  };

  const handleEmailBlur = (currentEmail: string) => {
    const trimmedEmail = currentEmail.trim();

    const error = validateEmail(trimmedEmail);
    setFieldValue('email', trimmedEmail);
    setFieldError('email', error === null ? '' : getMessage(error));
  };

  const handlePasswordBlur = (currentPassword: string) => {
    const error = validatePassword(currentPassword);
    setFieldError('password', error === null ? '' : getMessage(error));
  };

  const handlePhoneBlur = (currentPhone: string) => {
    const error = validatePhoneNumber(currentPhone);
    setFieldError('phoneNumber', error === null ? '' : getMessage(error));
  };

  const isNameFilled = selectedBorrowerType === BorrowerType.Person
    ? !!values.firstName.length && !!values.lastName
    : !!values.companyName;

  const isButtonEnabled = isNameFilled
    && !validatePhoneNumber(values.phoneNumber)
    && !validateEmail(values.email)
    && !validatePassword(values.password);

  const renderBorrowerName = () => {
    return (
      <>
        {selectedBorrowerType === BorrowerType.Person && (
          <BorrowerNamePerson
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
            onKeyDown={TriggerEventOnEnterKeyDown(emailInputRef, 'focus')}
          />
        )}
        {selectedBorrowerType === BorrowerType.Company && (
          <BorrowerNameCompany
            clearFieldErrorOnFocus={clearFieldErrorOnFocus}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            onChange={handleChange}
            isLoading={isLoading}
            onKeyDown={TriggerEventOnEnterKeyDown(emailInputRef, 'focus')}
          />
        )}
      </>
    );
  };

  const handleSetSelectedBorrowerType = (borrowerType: BorrowerType) => {
    resetForm();
    setSelectedBorrowerType(borrowerType);
  };

  return (
    <div>
      {createAccountFormType === CreateAccountFormBorrowerType.All && (
        <BorrowerTypeToggleGroupWrapper
          availableBorrowerTypes={availableBorrowerTypes}
          onChange={handleSetSelectedBorrowerType}
          selectedBorrowerType={selectedBorrowerType}
          buttonWrapperClassName={styles.toggleButton}
          labelTitle="Account Type"
          required
          optionTextOverwrite={{
            [BorrowerType.Person]: 'Personal Account',
            [BorrowerType.Company]: 'Business Account',
          }}
        />
      )}
      {renderBorrowerName()}
      <EmailInput
        labelTitle="Email"
        onChange={(value) => setFieldValue('email', value)}
        onFocus={() => clearFieldErrorOnFocus('email')}
        onBlur={() => handleEmailBlur(values.email)}
        value={values.email || ''}
        errorMessage={errors.email || ''}
        name="email"
        disabled={isLoading || !!inviteData?.email}
        required
        ref={emailInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(phoneNumberInputRef, 'focus')}
        autoComplete="username email"
      />
      <PhoneNumberInput
        withFlag
        labelTitle="Phone"
        value={values.phoneNumber}
        onChange={(value) => setFieldValue('phoneNumber', value)}
        onFocus={() => clearFieldErrorOnFocus('phoneNumber')}
        onBlur={() => handlePhoneBlur(values.phoneNumber)}
        name="phoneNumber"
        required
        country={phoneNumberFormat as CountryCode}
        errorMessage={errors.phoneNumber}
        disabled={isLoading}
        ref={phoneNumberInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        autoComplete="off"
      />
      <PasswordInput
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={(event) => setFieldValue('password', event.target.value)}
        onBlur={() => handlePasswordBlur(values.password)}
        onFocus={() => clearFieldErrorOnFocus('password')}
        errorMessage={errors.password}
        disabled={isLoading}
        ref={passwordInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        autoComplete="new-password"
      />
      <SubmitButton
        title="Create Account"
        isButtonEnabled={isButtonEnabled}
        onSubmit={() => handleSubmit({ ...values, borrowerType: selectedBorrowerType })}
        ref={submitButtonRef}
      />
    </div>
  );
};

export default CreateAccountForm;
