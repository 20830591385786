import { FC, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import ResetPasswordForm from 'components/Auth/forms/ResetPasswordForm';
import { IResetPasswordContextType } from 'components/Auth/forms/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import { createNotification } from 'handlers/notificationsSlice';
import getMessage, { MessageType } from 'constants/Messages';
import { useAppDispatch } from 'hooks/reduxHooks';

const ResetPassword: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: IResetPasswordContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(resetPassword(values.email));
      setIsLoading(false);
      createNotification({
        notification: getMessage(MessageType.PasswordResetEmailSent, { email: values.email }),
        type: 'success',
        dispatch,
      });
      navigate(AppRoute.SignIn);
    } catch (error) {
      setIsLoading(false);
      createNotification({ type: 'error', notification: error.message, dispatch });
    }
  };

  return (
    <UnauthorizedLayout
      formTitle="Reset Password"
      formSubtitle="Please enter your email address and we’ll send you a link to reset your password."
      footerLinkText="Back To Sign In"
      footerLink={AppRoute.SignIn}
    >
      <ResetPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
    </UnauthorizedLayout>
  );
};

export default ResetPassword;
