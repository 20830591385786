export class RequestError extends Error {
  constructor(
    message: string,
    readonly responseStatus: number,
    readonly errorType?: string,
    readonly data?: Record<string, unknown>,
  ) {
    super(message);
  }
}
