import { useState, useEffect, useCallback, useRef, FocusEvent } from 'react';
import { IBaseVariableConfiguration } from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { isEmptyVariableValue } from 'product_modules/utils/isEmptyVariableValue';
import { replaceAt } from 'product_modules/utils/arrayUtils';
import isTrimmableString from 'utils/isTrimmableString';

// This hook is applied to create entities (edit is not supported).
const useMultipleConfigurableFormData = (count: number) => {
  const [multipleData, setMultipleData] = useState(new Array(count).fill({}));
  const multipleDerivedChangesRef = useRef<Array<Set<string>>>(new Array(count).fill(new Set()));

  useEffect(() => {
    if (multipleData.length === count) {
      return;
    }

    setMultipleData(new Array(count).fill({}));

    multipleDerivedChangesRef.current = new Array<Set<string>>(count).fill(new Set());
  }, [count]);

  const handleFieldChange = useCallback((index: number, field: IBaseVariableConfiguration, variable: Variable, value: VariableValue) => {
    if (isEmptyVariableValue(value)) {
      multipleDerivedChangesRef.current[index].delete(variable.systemName);
    } else {
      multipleDerivedChangesRef.current[index].add(variable.systemName);
    }

    setMultipleData((previousMultipleData) => {
      return replaceAt(previousMultipleData, index, { ...previousMultipleData[index], [field.variable]: value });
    });

    return true;
  }, []);

  const handleFieldBlur = useCallback((
    index: number,
    field: IBaseVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement>,
  ) => {
    const visualDataType = variable.visualDataType;

    setMultipleData((previousMultipleData) => {
      if (!isTrimmableString(visualDataType)) {
        return replaceAt(previousMultipleData, index, previousMultipleData[index]);
      }

      return replaceAt(previousMultipleData, index, {
        ...previousMultipleData[index],
        [field.variable]: (event?.target.value as string)?.trim(),
      });
    });
  }, []);

  const isDataWasChanged = useCallback((index: number) => {
    return multipleDerivedChangesRef.current[index].size > 0;
  }, []);

  const resetFormData = useCallback((index) => {
    setMultipleData((previousMultipleData) => replaceAt(previousMultipleData, index, {}));
  }, []);

  return [
    multipleData,
    handleFieldChange,
    handleFieldBlur,
    resetFormData,
    isDataWasChanged,
  ] as [FormLayoutData[], typeof handleFieldChange, typeof handleFieldBlur, typeof resetFormData, typeof isDataWasChanged];
};

export default useMultipleConfigurableFormData;
