import { rgba } from 'polished';
import styled from 'types/AppTheme';
import ButtonStyles from 'product_modules/components/Button/Button.module.scss';
import ReactComponentType from 'types/ReactComponentType';
import { ButtonType } from 'product_modules/components/Button/Button';

export const PRIMARY_BUTTON_CLASS_NAME = 'primary-button';
export const SECONDARY_BUTTON_CLASS_NAME = 'secondary-button';
export const PRIMARY_RELAXED_BUTTON_CLASS_NAME = 'primary-relaxed-button';

export type ButtonTypeExtended = ButtonType | 'primary-relaxed';

export const getButtonClassName = (kind?: ButtonTypeExtended | null): string => {
  if (kind === 'secondary' || kind === 'with-state' || !kind) {
    return SECONDARY_BUTTON_CLASS_NAME;
  }

  if (kind === 'primary-relaxed') {
    return PRIMARY_RELAXED_BUTTON_CLASS_NAME;
  }

  return PRIMARY_BUTTON_CLASS_NAME;
};

const StyleButtonComponent = <Component extends ReactComponentType>(component: Component, elementType: 'root' | 'child') => {
  const selector = elementType === 'root' ? '&' : '';

  return styled(component)`
    ${selector}.${PRIMARY_BUTTON_CLASS_NAME}:not(.${ButtonStyles.disabled}) {
      background-color: ${({ theme }) => theme.colors.accentColor};
      border-color: ${({ theme }) => theme.colors.accentColor};

      &:hover, &:focus {
        background-color: ${({ theme }) => theme.colors.hoverAccentColor};
        border-color: ${({ theme }) => theme.colors.hoverAccentColor};
      }
    }

    ${selector}.${PRIMARY_BUTTON_CLASS_NAME}.${ButtonStyles.disabled}.${ButtonStyles.buttonLoading} {
      background-color: ${({ theme }) => theme.colors.hoverAccentColor};
      border-color: ${({ theme }) => theme.colors.hoverAccentColor};
    }
    
     ${selector}.${PRIMARY_RELAXED_BUTTON_CLASS_NAME}:not(.${ButtonStyles.disabled}) {
       background-color: ${({ theme }) => theme.colors.accentColor ? rgba(theme.colors.accentColor, 0.1): ''};
       color: ${({ theme }) => theme.colors.accentColor};
       border: none;

      &:hover, &:focus {
        background-color: ${({ theme }) => theme.colors.accentColor ? rgba(theme.colors.accentColor, 0.2) : ''};
        border: none;
      }
    }

    ${selector}.${PRIMARY_BUTTON_CLASS_NAME}.${ButtonStyles.disabled}.${ButtonStyles.buttonLoading} {
      background-color: ${({ theme }) => theme.colors.hoverAccentColor};
      border-color: ${({ theme }) => theme.colors.hoverAccentColor};
    }

    ${selector}.${SECONDARY_BUTTON_CLASS_NAME}:not(.${ButtonStyles.disabled}) {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.accentColor};
      color: ${({ theme }) => theme.colors.accentColor};

      &:hover, &:focus {
        background-color: ${({ theme }) => theme.colors.backgroundHoverAccentColor};
        border-color: ${({ theme }) => theme.colors.accentColor};
        color: ${({ theme }) => theme.colors.accentColor};
      }
    }

    ${selector}.${SECONDARY_BUTTON_CLASS_NAME}.${ButtonStyles.disabled}.${ButtonStyles.buttonLoading} {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.accentColor};
      color: ${({ theme }) => theme.colors.accentColor};

      .${ButtonStyles.loader} div {
        background-color: ${({ theme }) => theme.colors.accentColor} !important; // product component uses inline styles, so we have to use important
      }
    }
  `;
};

export default StyleButtonComponent;
