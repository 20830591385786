export type RequestBody = string | { [key: string]: any } | FormData;

interface IRequestErrorProps {
  message: string;
  type?: string;
}

export const getBasicHeaders = () => {
  const headers = new Headers();

  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  return headers;
};

export const stringifyRequestBody = (body?: RequestBody): string | FormData | undefined => {
  if (typeof body === 'string' || body instanceof FormData || typeof body === 'undefined') {
    return body;
  }
  return JSON.stringify(body);
};

export const getRequestErrorData = async (response: Response): Promise<IRequestErrorProps> => {
  try {
    const body = await response.json();
    const message = getErrorMessage(body, response);
    const type = getErrorType(body);

    return { message, type };
  } catch (error) {
    return { message: response.statusText };
  }
};

const getErrorMessage = (body: IRequestErrorProps, response: Response) => {
  return body.message || response.statusText;
};


const getErrorType = (body: IRequestErrorProps) => {
  if (!body.type) {
    return;
  }

  return body.type;
};
