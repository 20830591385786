import ContainerWithPatternStyled from 'components/BrandingHelpers/ContainerWithPatternStyled';
import styles from 'components/PageLayout/Header/Header.module.scss';
import { FC } from 'react';

const Header: FC = () => {
  return (
    <ContainerWithPatternStyled className={styles.headerContainer} />
  );
};

export default Header;
