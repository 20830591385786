import React, { FC, FocusEvent } from 'react';
import { IPageElementConfig } from 'api/digifi/PortalPageElementsApi';
import { useAppSelector } from 'hooks/reduxHooks';
import HeaderTextElement from 'components/ApplicationPage/elements/HeaderTextElement';
import SubHeaderTextElement from 'components/ApplicationPage/elements/SubHeaderTextElement';
import BodyTextElement from 'components/ApplicationPage/elements/BodyTextElement';
import PageDividerElement from 'components/ApplicationPage/elements/PageDividerElement';
import ImageElement from 'components/ApplicationPage/elements/ImageElement';
import TasksElement from 'components/ApplicationPage/elements/TasksElement';
import OfferSelectionElement from 'components/ApplicationPage/elements/OfferSelectionElement';
import DataInputElement from 'components/ApplicationPage/elements/DataInputElement';
import ButtonElement from 'components/ApplicationPage/elements/ButtonElement';
import ProgressBarElement from 'components/ApplicationPage/elements/ProgressBarElement';
import {
  ITextElementConfig,
  PortalPageElementType,
  IPageDividerElementConfig,
  IImageElementConfig,
  ITasksElementConfig,
  IOfferSelectionElementConfig,
  IDataInputElementConfig,
  IButtonElementConfig,
  IProgressBarElementConfig,
} from 'api/digifi/portal-page-elements';
import { pageElementMargins } from 'components/ApplicationPage/elements/utils/elementMargins';
import { TableValue, VariableValue } from 'product_modules/api/Types';
import { IPortalPageVariableConfiguration } from 'api/digifi/layout/VariableConfigurationsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';

export interface IPageElementProps<ConfigType extends IPageElementConfig> {
  config: ConfigType;
  productId: string;
  elementId: string;
  context: Record<string, VariableValue>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersVariableValue: TableValue | null;
  offersSelectionElementId: string | null;
  containerClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  isSubmitInProgress?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
}

interface IPortalPageElementMapType {
  [PortalPageElementType.HeaderText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.SubHeaderText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.BodyText]: FC<IPageElementProps<ITextElementConfig>>;
  [PortalPageElementType.PageDivider]: FC<IPageElementProps<IPageDividerElementConfig>>;
  [PortalPageElementType.Tasks]: FC<IPageElementProps<ITasksElementConfig>>;
  [PortalPageElementType.OfferSelection]: FC<IPageElementProps<IOfferSelectionElementConfig>>;
  [PortalPageElementType.Image]: FC<IPageElementProps<IImageElementConfig>>;
  [PortalPageElementType.Button]: FC<IPageElementProps<IButtonElementConfig>>;
  [PortalPageElementType.DataInput]: FC<IPageElementProps<IDataInputElementConfig>>;
  [PortalPageElementType.ProgressBar]: FC<IPageElementProps<IProgressBarElementConfig>>;
}

const portalPageElementByType: IPortalPageElementMapType = {
  [PortalPageElementType.HeaderText]: HeaderTextElement,
  [PortalPageElementType.SubHeaderText]: SubHeaderTextElement,
  [PortalPageElementType.BodyText]: BodyTextElement,
  [PortalPageElementType.PageDivider]: PageDividerElement,
  [PortalPageElementType.Tasks]: TasksElement,
  [PortalPageElementType.OfferSelection]: OfferSelectionElement,
  [PortalPageElementType.Image]: ImageElement,
  [PortalPageElementType.Button]: ButtonElement,
  [PortalPageElementType.DataInput]: DataInputElement,
  [PortalPageElementType.ProgressBar]: ProgressBarElement,
};

interface IPageElementComponentProps {
  productId: string;
  elementId: string;
  context: Record<string, VariableValue>;
  isElementLoadingByType: Record<PortalPageElementType, boolean>;
  portalPageFormData: Record<string, VariableValue>;
  onPortalPageFieldChange: (field: IPortalPageVariableConfiguration, variable: Variable, value: VariableValue) => void;
  onPortalPageFieldBlur: (field: IPortalPageVariableConfiguration, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  onSubmitPageData: () => Promise<void>;
  offersVariableValue: TableValue | null;
  onOfferSelect: (updatedOffers: TableValue) => void;
  offersSelectionElementId: string | null;
  containerRef?: React.Ref<HTMLDivElement>;
}

const PageElement: FC<IPageElementComponentProps> = ({
  productId,
  elementId,
  context,
  isElementLoadingByType,
  portalPageFormData,
  onPortalPageFieldChange,
  onPortalPageFieldBlur,
  onSubmitPageData,
  offersVariableValue,
  onOfferSelect,
  offersSelectionElementId,
  containerRef,
}) => {
  const element = useAppSelector((state) => state.portalPageElements.entities[elementId]);

  const isSubmitInProgress = useAppSelector((state) => state.applicationData.isSubmitInProgress);

  if (!element) {
    return null;
  }

  const Element = portalPageElementByType[element.elementType] as FC<IPageElementProps<IPageElementConfig>>;
  const className = pageElementMargins[element.elementType];

  return (
    <Element
      productId={productId}
      config={element.config}
      elementId={elementId}
      context={context}
      containerClassName={className}
      loading={isElementLoadingByType[element.elementType]}
      portalPageFormData={portalPageFormData}
      onPortalPageFieldChange={onPortalPageFieldChange}
      onPortalPageFieldBlur={onPortalPageFieldBlur}
      onSubmit={onSubmitPageData}
      disabled={isSubmitInProgress}
      isSubmitInProgress={isSubmitInProgress}
      offersVariableValue={offersVariableValue}
      onOfferSelect={onOfferSelect}
      offersSelectionElementId={offersSelectionElementId}
      containerRef={containerRef}
    />
  );
};

export default PageElement;
