import { MessageType } from 'constants/Messages';
import isEmail from 'validator/lib/isEmail';
import { isValidPhoneNumber } from 'libphonenumber-js';

const MIN_PASSWORD_LENGTH = 8;

export const validateEmail = (email: string): MessageType | null => {
  if (!email.length) {
    return MessageType.EmailRequired;
  }

  if (!isEmail(email)) {
    return MessageType.EmailInvalid;
  }

  return null;
};

export const validatePassword = (password: string): MessageType | null => {
  if (!password.length) {
    return MessageType.PasswordRequired;
  }

  if (password.length < MIN_PASSWORD_LENGTH || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
    return MessageType.PasswordRequirementsNotMet;
  }

  return null;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.length) {
    return MessageType.PhoneNumberRequired;
  }

  return isValidPhoneNumber(phoneNumber) ? null : MessageType.PhoneNumberInvalid;
};
