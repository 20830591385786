import { FocusEvent, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import useConditionalDisplayLogic from 'product_modules/hooks/useConditionalDisplayLogic';
import { useVariablesFormValidation } from 'product_modules/components/ConfigurableForm';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import {
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import { LayoutConfigurationReferenceType } from 'product_modules/enums/LayoutConfigurationReferenceType';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import {
  useLayoutConfigurationVariables,
} from 'product_modules/loaders/LayoutConfiguration/hooks/useLayoutConfigurationVariables';
import Header from 'product_modules/components/Header';
import CardsForm from 'components/digifi-wrappers/CardsForm/CardsForm';
import BorrowerTypeToggleGroup from 'components/digifi-wrappers/BorrowerTypeToggleGroup';
import useFullApplicationData from 'hooks/useFullApplicationData';
import { formatDisplayTitle } from 'utils/formatDisplayTitle';
import {
  useLayoutConfigurationReferenceKeyWithLayoutFetch,
} from 'hooks/useLayoutConfigurationReferenceKeyWithLayoutFetch';
import { ArrowRightBold } from 'static/images';
import styles from './BorrowerProfileForm.module.scss';

interface IBorrowerProfileFormProps<FieldType extends IBaseVariableConfiguration> {
  onFieldChange: (field: FieldType, variable: Variable, value: VariableValue) => void;
  onFieldBlur?: (field: FieldType, variable: Variable, event?: FocusEvent<HTMLInputElement>) => void;
  borrowerFormData: FormLayoutData;
  coBorrowersFormData: Array<FormLayoutData>;
  selectedBorrowerType: BorrowerType;
  availableBorrowerTypes: BorrowerType[];
  onFormValidationChange: (isFormValid: boolean) => void;
  onChangeBorrowerType?: (borrowerType: BorrowerType) => void;
  isBorrowerForm?: boolean;
  formTitle?: string;
  sectionTitleClassName?: string;
}

const storageLayoutEntitiesPaths = {
  groups: 'borrowerProfileGroups',
  cards: 'borrowerProfileCards',
  variablesConfigurations: 'borrowerProfileVariableConfigurations',
};

const BorrowerProfileForm = <FieldType extends IBaseVariableConfiguration>({
  onFieldChange,
  borrowerFormData,
  coBorrowersFormData,
  onFieldBlur,
  selectedBorrowerType,
  availableBorrowerTypes,
  onChangeBorrowerType,
  isBorrowerForm,
  formTitle,
  sectionTitleClassName,
  onFormValidationChange,
}: IBorrowerProfileFormProps<FieldType>) => {
  const [collapseSection, setCollapseSection] = useState(false);

  const referenceKey = useLayoutConfigurationReferenceKeyWithLayoutFetch({
    referenceType: LayoutConfigurationReferenceType.BorrowerProfile,
    reference: selectedBorrowerType,
  });

  const layoutConfigurationVariables = useLayoutConfigurationVariables<FieldType>(
    referenceKey,
    storageLayoutEntitiesPaths.variablesConfigurations,
  );

  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    coBorrowersFormData,
  });

  const displayFieldsAttributes = useConditionalDisplayLogic(
    layoutConfigurationVariables || null,
    fullApplicationData,
  );

  const isBorrowerDataValid = useVariablesFormValidation(
    layoutConfigurationVariables || null,
    fullApplicationData,
    displayFieldsAttributes,
  );

  const handleCollapseIconClick = useCallback(() => {
    setCollapseSection((prevState) => !prevState);
  }, []);

  useEffect(() => {
    onFormValidationChange(isBorrowerDataValid);
  }, [isBorrowerDataValid]);

  return (
    <>
      {formTitle && (
        <div className={clsx(styles.sectionTitleContainer, sectionTitleClassName)}>
          <Header type="h3" className={styles.sectionTitle}>{formTitle}</Header>
          <ArrowRightBold className={clsx(styles.arrowIcon, collapseSection && styles.arrowIconCollapsed)} onClick={handleCollapseIconClick} />
        </div>
      )}
      <Collapse
        in={!collapseSection}
        unmountOnExit
      >
        <div className={styles.container}>
          {!isBorrowerForm && availableBorrowerTypes.length === Object.keys(BorrowerType).length && (
            <BorrowerTypeToggleGroup
              className={styles.borrowerTypeButtonToggleGroup}
              selectedBorrowerType={selectedBorrowerType}
              onChange={(type) => onChangeBorrowerType?.(type)}
              availableBorrowerTypes={availableBorrowerTypes}
            />
          )}
          <div className={clsx(formTitle && styles.cardFormContainer)}>
            <CardsForm
              referenceKey={referenceKey}
              storageLayoutEntitiesPaths={storageLayoutEntitiesPaths}
              onFieldChange={onFieldChange}
              onFieldBlur={onFieldBlur}
              data={fullApplicationData || {}}
              isEditMode
              displayHeader={false}
              displaySkeleton={!displayFieldsAttributes}
              displayFieldsAttributes={displayFieldsAttributes}
              formatVariableConfigurationDisplayTitle={formatDisplayTitle}
            />
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default BorrowerProfileForm;
