import { FC, useState } from 'react';
import CreateAccountForm from 'components/Auth/forms/CreateAccountForm';
import { acceptInvite, getCurrentAccount } from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { CreateAccountFormType } from 'types';
import { createNotification } from 'handlers/notificationsSlice';

interface IAcceptInviteProps {
  token: string;
}

const AcceptInvite: FC<IAcceptInviteProps> = ({ token }) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: CreateAccountFormType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(acceptInvite({ params: values, token }));
      await dispatchWithUnwrap(getCurrentAccount());
    } catch (error) {
      createNotification({ notification: error.message, type: 'error', dispatch });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CreateAccountForm handleSubmit={handleSubmit} isLoading={isLoading} />
  );
};

export default AcceptInvite;
