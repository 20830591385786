import { FC } from 'react';
import Button, { ButtonProps } from 'product_modules/components/Button';
import clsx from 'clsx';
import StyleButtonComponent, {
  ButtonTypeExtended,
  getButtonClassName,
} from 'components/digifi-wrappers/Button/StyleButtonComponent';
import { ButtonType } from 'product_modules/components/Button/Button';

const ButtonStyled = StyleButtonComponent(Button, 'root');

export interface IButtonWrapperProps extends Omit<ButtonProps, 'kind'> {
  kind: ButtonTypeExtended;
}

const ButtonWrapper: FC<IButtonWrapperProps> = (props) => {
  return (
    <ButtonStyled
      {...props}
      kind={props.kind as ButtonType}
      className={clsx(getButtonClassName(props.kind), props.className)}
    />
  );
};

export default ButtonWrapper;
