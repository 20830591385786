import { FC, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import CreateNewPasswordForm from 'components/Auth/forms/CreateNewPasswordForm';
import { ICreateNewPasswordContextType } from 'components/Auth/forms/CreateNewPasswordForm/CreateNewPasswordForm';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createNewPassword } from 'handlers/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import { createNotification } from 'handlers/notificationsSlice';
import { useAppDispatch } from 'hooks/reduxHooks';

const CreateNewPassword: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.pathname.split('/').reverse()[0];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: ICreateNewPasswordContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(createNewPassword({ password: values.password, resetPasswordToken: token ||  '' }));
      setIsLoading(false);
      createNotification({
        notification: 'Your password has been updated.',
        type: 'success',
        dispatch,
      });

      navigate(AppRoute.SignIn);
    } catch (error) {
      setIsLoading(false);
      createNotification({
        notification: error.message,
        type: 'error',
        dispatch,
      });
    }
  };

  return (
    <UnauthorizedLayout
      formTitle="Create New Password"
      footerLinkText="Back To Sign In"
      footerLink={AppRoute.SignIn}
    >
      <CreateNewPasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
    </UnauthorizedLayout>
  );
};

export default CreateNewPassword;
