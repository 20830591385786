import { FC } from 'react';
import clsx from 'clsx';
import HomeButton from 'components/common/HomeButton';
import Logo from 'components/common/Logo';
import BorrowerDetails from 'components/ApplicationsList/BorrowerDetails';
import { useAppSelector } from 'hooks/reduxHooks';
import useCurrentBorrower from 'hooks/useCurrentBorrower';
import { getBorrowerFullName } from 'utils/borrowerNameHelper';
import styles from './ApplicationsHeader.module.scss';

interface IApplicationsHeaderProps {
  onHomeButtonClick?: () => void;
  onLoginDetailsClick?: () => void;
  onLogoutClick?: () => void;
  isLoading?: boolean;
  hideBorrowerDetails?: boolean;
  noShadow?: boolean;
  className?: string;
}

const ApplicationsHeader: FC<IApplicationsHeaderProps> = ({
  isLoading,
  hideBorrowerDetails,
  onLogoutClick,
  onLoginDetailsClick,
  noShadow,
  className,
  onHomeButtonClick,
}) => {
  const borrower = useCurrentBorrower();
  const { accountData } = useAppSelector(state => state.auth);

  return (
    <div className={clsx(
      styles.headerContainer,
      !noShadow && styles.containerShadow,
      className,
    )}>
      <div className={styles.headerContent}>
        <Logo />
        <div className={styles.rightSideContainer}>
          <HomeButton onClick={onHomeButtonClick} />
          {!hideBorrowerDetails && (
            <BorrowerDetails
              borrowerName={getBorrowerFullName(borrower?.variables, borrower?.type) || accountData?.fullName || ''}
              onLoginDetailsClick={onLoginDetailsClick}
              onLogoutClick={onLogoutClick}
              isLoading={isLoading}
            />
          )}
        </div>

      </div>
    </div>
  );
};

export default ApplicationsHeader;
