import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';
import authReducer, { AccountsActionType } from 'handlers/authSlice';
import notificationsReducer from './notificationsSlice';
import settingsReducer from './settingsSlice';
import applicationsReducer from 'handlers/applicationsSlice';
import borrowersReducer from 'handlers/borrowersSlice';
import documentsReducer from 'handlers/documentsSlice';
import snacksReducer from 'handlers/snacksSlice';
import productsReducer from 'handlers/productsSlice';
import borrowerProfileGroupsReducer from 'handlers/borrowerProfileGroupsSlice';
import borrowerProfileCardsReducer from 'handlers/borrowerProfileCardsSlice';
import BorrowerProfileVariableConfigurationsReducer from 'handlers/borrowerProfileVariableConfigurationsSlice';
import activeBorrowerInformationReducer from 'handlers/activeBorrowerInformationSlice';
import variablesReducer from './variablesSlice';
import layoutConfigurationsReducer from './layoutConfigurationsSlice';
import portalPageElementsReducer from './portalPageElementsSlice';
import tasksReducer from './tasksSlice';
import applicationDataReducer from './applicationDataSlice';
import portalPageGroupsReducer from './portalPageGroupsSlice';
import portalPageCardsReducer from './portalPageCardsSlice';
import portalPageVariableConfigurationsReducer from './portalPageVariableConfigurationsSlice';
import { IReduxState } from 'types/ReduxState';
import automationWorkflowReducer from 'handlers/automationWorkflowSlice';

const appReducer = combineReducers<IReduxState>({
  auth: authReducer,
  notifications: notificationsReducer,
  settings: settingsReducer,
  applications: applicationsReducer,
  borrowers: borrowersReducer,
  applicationDocuments: documentsReducer,
  snacks: snacksReducer,
  products: productsReducer,
  borrowerProfileGroups: borrowerProfileGroupsReducer,
  borrowerProfileCards: borrowerProfileCardsReducer,
  borrowerProfileVariableConfigurations: BorrowerProfileVariableConfigurationsReducer,
  variables: variablesReducer,
  layoutConfigurations: layoutConfigurationsReducer,
  activeBorrowerInformation: activeBorrowerInformationReducer,
  portalPageElements: portalPageElementsReducer,
  tasks: tasksReducer,
  applicationData: applicationDataReducer,
  automationWorkflows: automationWorkflowReducer,
  portalPageGroups: portalPageGroupsReducer,
  portalPageCards: portalPageCardsReducer,
  portalPageVariableConfigurations: portalPageVariableConfigurationsReducer,
});

const rootReducer = (state: IReduxState | undefined, action: AnyAction): CombinedState<IReduxState> => {
  if (!state) {
    return appReducer(state, action);
  }

  let nextState: Partial<IReduxState> = state;

  if (action.type === `${AccountsActionType.Logout}/fulfilled` || action.type === `${AccountsActionType.Logout}/rejected`) {
    nextState = {
      settings: state.settings,
      notifications: state.notifications,
    };
  }

  return appReducer(nextState as IReduxState, action);
};

export default rootReducer;
