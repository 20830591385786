import { FC } from 'react';
import Header from 'components/PageLayout/Header';
import AuthFormContainer from 'components/Auth/AuthFormContainer';
import styles from './UnauthorizedLayout.module.scss';
import FormTitle from 'components/PageLayout/FormTitle';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import { AppRoute } from 'enums/AppRoute';
import MainLayout from 'layout/MainLayout';
import { logout } from 'handlers/authSlice';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';

interface IUnauthorizedLayoutProps {
  formTitle: string;
  footerText?: string;
  footerLinkText?: string;
  footerLink?: AppRoute;
  formSubtitle?: string | JSX.Element;
  logoutOnLinkClick?: true;
}

const UnauthorizedLayout: FC<IUnauthorizedLayoutProps> = ({ formTitle, formSubtitle, footerText, footerLinkText, footerLink, logoutOnLinkClick, children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate(footerLink || AppRoute.SignIn);
  };

  return (
    <MainLayout
      contentClassName={styles.mainLayoutContent}
      innerContentClassName={styles.innerContent}
      renderElementBeforeContent={() => <Header />}
    >
      <div className={styles.authLayoutContent}>
        <AuthFormContainer>
          <FormTitle title={formTitle} subTitle={formSubtitle} />
          <div className={styles.formContainer}>
            {children}
          </div>
        </AuthFormContainer>
        {footerLinkText && footerLink && (
          <div className={styles.footerLinkContainer}>
            <FormFooterLink
              text={footerText}
              linkText={footerLinkText}
              to={footerLink}
              onLinkClick={logoutOnLinkClick && handleLogout}
              className={styles.footerLink}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};
export default UnauthorizedLayout;
