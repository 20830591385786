import { FC } from 'react';
import styles from 'components/Auth/AuthFormContainer/AuthFormContainer.module.scss';
import Logo from 'components/common/Logo';

const AuthFormContainer: FC = ({ children }) => {
  return (
    <div className={styles.authFormContainer}>
      <div className={styles.authFormHeader}>
        <Logo />
      </div>
      <div className={styles.authFormContent}>
        {children}
      </div>
    </div>
  );
};

export default AuthFormContainer;
