import { FC, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from 'enums/AppRoute';
import { useAppSelector } from 'hooks/reduxHooks';
import createPathWithBackUrl from 'utils/redirectWithBackUrl';
import useBackUrlQueryParam from 'hooks/useBackUrlQueryParam';
import { AUTH_ROUTES } from 'api/BaseApi';

const RedirectHandler: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { accountData } = useAppSelector(state => state.auth);
  const backUrl = useBackUrlQueryParam();

  const redirectWithBackUrl = (path: string) => {
    return navigate(createPathWithBackUrl(path, backUrl));
  };

  const checkSession = () => {
    const isAuthRoute = AUTH_ROUTES.some((path) => matchPath(path, location.pathname));

    if (!accountData && !matchPath({ path: location.pathname }, '/')) {
      return;
    }

    if (!accountData?.borrowerId) {
      return isAuthRoute
        ? undefined
        : redirectWithBackUrl(AppRoute.SignIn);
    }

    if (!accountData.isEmailVerified) {
      return redirectWithBackUrl(AppRoute.ConfirmEmail);
    }

    if (!accountData.isMfaComplete) {
      return redirectWithBackUrl(AppRoute.PhoneVerification);
    }

    if (isAuthRoute || matchPath('/', location.pathname)) {
      return navigate(backUrl || AppRoute.Home);
    }
  };

  useEffect(() => {
    checkSession();
  }, [accountData]);

  return null;
};

export default RedirectHandler;
