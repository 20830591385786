import { FC } from 'react';
import useCurrentBorrower from 'hooks/useCurrentBorrower';
import { getBorrowerFirstName } from 'utils/borrowerNameHelper';
import styles from './GreetingsBlock.module.scss';

const GreetingsBlock: FC = () => {
  const borrower = useCurrentBorrower();

  return (
    <div className={styles.title}>
      Hello, {getBorrowerFirstName(borrower?.variables, borrower?.type)} 👋
    </div>
  );
};

export default GreetingsBlock;
