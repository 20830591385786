import { FC } from 'react';
import WithFieldsValidationButton, { WithFieldsValidationButtonProps } from 'product_modules/components/WithFieldsValidationButton';
import clsx from 'clsx';
import StyleButtonComponent, { getButtonClassName } from 'components/digifi-wrappers/Button/StyleButtonComponent';

const WithFieldsValidationButtonWrapperStyled = StyleButtonComponent(WithFieldsValidationButton, 'child');

const WithFieldsValidationButtonWrapper: FC<WithFieldsValidationButtonProps> = (props) => {
  return (
    <WithFieldsValidationButtonWrapperStyled
      {...props}
      buttonClassName={clsx(props.buttonClassName, getButtonClassName(props.kind))}
    />
  );
};

export default WithFieldsValidationButtonWrapper;
