import { FC, FocusEvent, useCallback, useEffect, useState, useMemo } from 'react';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import CreateApplicationForm from 'components/ApplicationForm/CreateApplicationForm';
import { VariableValue } from 'product_modules/api/Types';
import { getExistingBorrowerData, updateBorrower } from 'handlers/borrowersSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { createApplication } from 'handlers/applicationsSlice';
import { IProduct } from 'api/digifi/ProductsApi';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import {
  IBaseVariableConfiguration,
} from 'product_modules/api/LoanOriginationSystem/Base/LayoutConfigurationApi';
import useMultipleConfigurableFormData from 'components/ApplicationForm/CreateApplication/useMultipleConfigurableFormData';
import {
  isCoBorrowerApplicationFormPage,
} from 'components/ApplicationForm/CreateApplication/utils';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import { replaceAt } from 'product_modules/utils/arrayUtils';
import {
  setAllowToDiscardNewApplication,
  setCurrentApplication,
} from 'handlers/activeBorrowerInformationSlice';
import isTrimmableString from 'utils/isTrimmableString';
import useCurrentBorrower from 'hooks/useCurrentBorrower';
import useAsyncActionCallback from 'product_modules/hooks/useAsyncActionCallback';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { AppRoute } from 'enums/AppRoute';

interface ICreateApplicationProps {
  product: IProduct;
  coborrowersCount: number;
}

const CreateApplication: FC<ICreateApplicationProps> = ({
  product,
  coborrowersCount,
}) => {
  const dispatch = useAppDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const availableCoBorrowerPages = useMemo(() => {
    return Object.keys(product.applicationFormPages).filter((page) => isCoBorrowerApplicationFormPage(page));
  }, [product.applicationFormPages]);

  const borrower = useCurrentBorrower();
  const [borrowerProfileFormData, setBorrowerProfileFormData] = useState<Record<string, VariableValue>>({});

  const defaultBorrowerType = borrower.type;

  const [coBorrowersTypes, setCoBorrowersTypes] = useState<Array<BorrowerType>>(new Array(availableCoBorrowerPages.length).fill(defaultBorrowerType));
  const [createdApplication, setCreatedApplication] = useState<IApplicationViewModel | null>(null);

  const [
    multipleCoBorrowersFormData,
    handleMultipleCoBorrowersDataChange,
    handleMultipleCoBorrowersFieldBlur,
    resetCoBorrowerFormData,
  ] = useMultipleConfigurableFormData(coborrowersCount);

  useEffect(() => {
    setBorrowerProfileFormData({...borrower.variables, ...borrowerProfileFormData});
  }, [borrower]);

  useEffect(() => {
    dispatch(getExistingBorrowerData());
  }, []);

  const handleBorrowerProfileFieldChange = useCallback((
    _variableConfiguration: IBaseVariableConfiguration,
    variable: Variable,
    value: VariableValue,
  ) => {
    setBorrowerProfileFormData((previousBorrowerFormData) => ({
      ...previousBorrowerFormData,
      [variable.systemName]: value,
    }));
  }, []);

  const handleBorrowerProfileFieldBlur = useCallback((
    _variableConfiguration: IBaseVariableConfiguration,
    variable: Variable,
    event?: FocusEvent<HTMLInputElement>,
  ) => {
    const visualDataType = variable.visualDataType;

    setBorrowerProfileFormData((previousBorrowerFormData) => {
      if (!isTrimmableString(visualDataType)) {
        return previousBorrowerFormData;
      }

      return {
        ...previousBorrowerFormData,
        [variable.systemName]: (event?.target.value as string)?.trim(),
      };
    });
  }, []);

  const [isSubmitInProgress, handleCreateApplication] = useAsyncActionCallback(async () => {
    await dispatchWithUnwrap(updateBorrower({ variables: borrowerProfileFormData }));

    if (!product) {
      return;
    }

    const application = await dispatchWithUnwrap(
      createApplication({
        productId: product.id,
        coborrowers: multipleCoBorrowersFormData.map((variables, index) => ({
          type: coBorrowersTypes[index],
          variables,
        })),
      }),
    );

    setCreatedApplication(application);
    dispatch(setCurrentApplication(application.id));
  }, [product, borrowerProfileFormData, multipleCoBorrowersFormData]);

  const handleSubmit = async () => {
    dispatch(setAllowToDiscardNewApplication(false));
    await handleCreateApplication();
  };

  const handleCoBorrowerTypeChange = (type: BorrowerType, index: number) => {
    setCoBorrowersTypes(replaceAt(coBorrowersTypes, index, type));
    resetCoBorrowerFormData(index);
  };

  const handleCreateApplicationFinish = () => {
    if (createdApplication) {
      navigate(`${AppRoute.Application}/${createdApplication?.displayId}`);
    }
  };

  return (
    <CreateApplicationForm
      coBorrowersFormData={multipleCoBorrowersFormData}
      onCoBorrowerFieldChange={handleMultipleCoBorrowersDataChange}
      borrowerType={borrower.type}
      borrowerProfileFormData={borrowerProfileFormData}
      onBorrowerFieldChange={handleBorrowerProfileFieldChange}
      onBorrowerFieldBlur={handleBorrowerProfileFieldBlur}
      onChangeCoBorrowerType={handleCoBorrowerTypeChange}
      onCoBorrowerFieldBlur={handleMultipleCoBorrowersFieldBlur}
      coBorrowersTypes={coBorrowersTypes}
      availableBorrowerTypes={product.borrowerTypes}
      coborrowersCount={coborrowersCount}
      onSubmit={handleSubmit}
      onCreateApplicationFinish={handleCreateApplicationFinish}
      isSubmitInProgress={isSubmitInProgress}
    />
  );
};

export default CreateApplication;
