import React, { useState, DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './PasswordInput.module.scss';
import clsx from 'clsx';
import useRandomIdFallback from 'product_modules/hooks/randomIdFallback';
import Label from 'product_modules/components/Label';

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps extends Omit<HTMLInputProps, 'ref'> {
  id?: string;
  labelTitle: string;
  errorMessage?: string;
  topRightElement?: ReactNode;
  containerClassName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { id: providedId, labelTitle, onChange, errorMessage, value, topRightElement, containerClassName, required, disabled, onFocus, onBlur, ...inputProps },
    ref,
  ) => {
    const id = useRandomIdFallback(providedId);
    const [isVisible, setVisible] = useState(false);
    const [isFocused, setFocus] = useState(false);
    const toggleFocusInput = () => {
      setFocus(!isFocused);
    };
    const toggleVisibility = () => {
      setVisible(!isVisible);
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocusInput();
      onFocus?.(e);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      toggleFocusInput();
      onBlur?.(e);
    }

    const labelClassNames = clsx(
      styles.inputContainer__input,
      isFocused && styles.inputFocus,
      errorMessage && styles.inputError,
      disabled && styles.inputDisabled,
    );

    return (
      <div className={clsx(styles.inputContainer, containerClassName)}>
        <div className={styles.labelWithLink}>
          <Label htmlFor={id} className={styles.label} required={required}>
            {labelTitle}
          </Label>
          {topRightElement}
        </div>
        <label className={labelClassNames}>
          <input
            type={isVisible ? 'text' : 'password'}
            id={id}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            autoComplete="none"
            className={styles.input}
            ref={ref}
            disabled={disabled}
            {...inputProps}
          />
          <button
            type="button"
            tabIndex={-1}
            className={clsx(isVisible ? styles.hideButton : styles.showButton, { [styles.disabledButton]: disabled })}
            onClick={toggleVisibility}
            disabled={disabled}
          >
            {' '}
          </button>
        </label>
        {errorMessage && <p className={styles.inputContainer__error}>{errorMessage}</p>}
      </div>
    );
  },
);

export default PasswordInput;
