import React, { FC } from 'react';
import TextInput from 'components/digifi-wrappers/TextInput';
import { FormikErrors } from 'formik';
import { MessageType } from 'constants/Messages';
import { ICreateAccountFormCompany } from 'types';

interface ICreateAccountFormCompanyProps {
  errors: FormikErrors<ICreateAccountFormCompany>;
  values: ICreateAccountFormCompany;
  clearFieldErrorOnFocus: (name: string) => void;
  onBlur: (name: string, errorMessage: MessageType, value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const BorrowerNameCompany: FC<ICreateAccountFormCompanyProps> = ({ clearFieldErrorOnFocus, onBlur, errors, values, onChange, onKeyDown, isLoading }) => {
  return (
    <TextInput
      labelTitle="Company Name"
      placeholder="Company"
      required
      disabled={isLoading}
      name="companyName"
      errorMessage={errors.companyName}
      onChange={onChange}
      onFocus={() => clearFieldErrorOnFocus('companyName')}
      onBlur={() => onBlur('companyName', MessageType.CompanyNameRequired, values.companyName)}
      onKeyDown={onKeyDown}
      value={values.companyName}
    />
  );
};

export default BorrowerNameCompany;
