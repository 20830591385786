import { FC } from 'react';
import Button from 'components/digifi-wrappers/Button';
import { IButtonElementConfig } from 'api/digifi/portal-page-elements';
import styles from './ButtonElement.module.scss';

const DEFAULT_BUTTON_TEXT = 'Button';

interface IButtonElementProps {
  config: IButtonElementConfig;
  onSubmit: () => Promise<void>;
  containerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const ButtonElement: FC<IButtonElementProps> = ({
  config,
  onSubmit,
  containerClassName,
  disabled,
  isLoading,
}) => {
  return (
    <div className={containerClassName} style={{ textAlign: config.alignment }} >
      <Button
        size="form"
        kind="primary"
        disabled={disabled}
        onClick={onSubmit}
        isLoading={isLoading}
        className={styles.button}
      >
        {config.text || DEFAULT_BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default ButtonElement;
