import { FC, useCallback } from 'react';
import clsx from 'clsx';
import { ApplicationFormPageBehavior, ApplicationFormPageType, IProduct } from 'api/digifi/ProductsApi';
import CheckboxWrapper from 'components/digifi-wrappers/Checkbox/Checkbox';
import {
  getCoBorrowerPageIndex,
  getCoBorrowerPageTypeByIndex,
  isCoBorrowerApplicationFormPage,
} from 'components/ApplicationForm/CreateApplication/utils';
import { ApplicationFormStep } from 'enums/ApplicationFormStep';
import styles from './ProductOptions.module.scss';

export interface IProductOptions {
  coborrowers: ApplicationFormStep[];
}

interface IProductOptionsProps {
  product: IProduct;
  options: IProductOptions;
  onChange: (options: IProductOptions) => void;
  className?: string;
}

const COBORROWER_INDEX_NAME = ['', 'second', 'third'];

const ProductOptions: FC<IProductOptionsProps> = ({
  product,
  options,
  className,
  onChange,
}) => {
  const coborrowerPages = Object
    .keys(product.applicationFormPages)
    .filter((page) => isCoBorrowerApplicationFormPage(page)) as ApplicationFormStep[];

  const filterSelectedCoBorrowersByIndex = (index: number) => {
    return options.coborrowers.filter((page) => {
      const pageIndex = getCoBorrowerPageIndex(page);

      return pageIndex < index;
    });
  };

  const handleOnCheck = (page: ApplicationFormStep) => () => {
    const index = getCoBorrowerPageIndex(page);

    const newSelectedCoBorrowerPages = options.coborrowers.includes(page)
      ? filterSelectedCoBorrowersByIndex(index)
      : [...options.coborrowers, page];

    onChange({
      coborrowers: newSelectedCoBorrowerPages,
    });
  };

  const isDisabled = (step: ApplicationFormStep) => {
    const index = getCoBorrowerPageIndex(step);

    const page = step as unknown as ApplicationFormPageType;

    const disabled = index !== 0 && !options.coborrowers.includes(getCoBorrowerPageTypeByIndex(index - 1));
    const isRequired = product.applicationFormPages[page] === ApplicationFormPageBehavior.Required;

    return disabled || isRequired;
  };

  const getCheckboxLabel = useCallback((index: number) => {
    return (
      <p className={styles.checkboxLabel}>{`Include a ${COBORROWER_INDEX_NAME[index]} co-borrower on this application`}</p>
    );
  }, [coborrowerPages]);

  if (coborrowerPages.length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.optionsTitle}>Co-Borrower Options</div>

      {coborrowerPages.map((page, index) => (
        <CheckboxWrapper
          key={page}
          disabled={isDisabled(page)}
          label={getCheckboxLabel(index)}
          checked={options.coborrowers.includes(page)}
          onChange={() => handleOnCheck(page)()}
        />
      ))}
    </div>
  );
};

export default ProductOptions;
