import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import LoginDetails from 'components/BorrowerProfile/LoginDetails';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import ChangeEmailPopup from 'components/BorrowerProfile/LoginDetails/ChangeEmailPopup';
import ChangePhonePopup from 'components/BorrowerProfile/LoginDetails/ChangePhonePopup';
import ChangePasswordPopup from 'components/BorrowerProfile/LoginDetails/ChangePasswordPopup';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  createPasswordValidationToken,
  sendPhoneVerificationCode,
  sendUpdateEmailAddressCode,
  sendUpdatePhoneNumberCode,
  updateEmailAddress,
  updatePassword,
  updatePhoneNumber,
  verifyPhone,
  logout,
} from 'handlers/authSlice';
import { AppRoute } from 'enums/AppRoute';
import MainLayout from 'layout/MainLayout';
import AuthorizedHeader from 'components/PageLayout/AuthorizedHeader';
import styles from './AuthorizedLayout.module.scss';
import { ITestModeNotificationItems } from 'components/TestModeNotificationList/TestModeNotificationList';
import usePopupsWithRouting from 'hooks/usePopupsWithRouting';

interface IAuthorizedLayoutProps {
  isLoading?: boolean;
  applicationsBrowserTitle?: string;
  layoutContentClassName?: string;
  hideBorrowerDetails?: boolean;
  showApplicationHeader?: boolean;
  innerContentClassName?: string;
  testModeNotifications?: ITestModeNotificationItems[];
  renderApplicationHeader?: () => ReactNode;
  onHomeButtonClick?: () => void;
}

const LOGIN_DETAILS_POPUPS = [
  'loginDetails',
  'changeEmail',
  'changePhone',
  'changePassword',
];

const AuthorizedLayout: FC<IAuthorizedLayoutProps> = ({
  children,
  layoutContentClassName,
  isLoading,
  applicationsBrowserTitle,
  hideBorrowerDetails,
  showApplicationHeader,
  innerContentClassName,
  testModeNotifications,
  renderApplicationHeader,
  onHomeButtonClick,
}) => {
  const { accountData } = useAppSelector(state => state.auth);
  const { country } = useAppSelector(state => state.settings.branding);

  const {
    closePopup,
    openPopup,
    renderPopups,
    topPopupKey,
  } = usePopupsWithRouting({
    loginDetails: (props) => (
      <LoginDetails
        {...props}
        email={accountData?.email}
        phone={accountData?.phone}
        disabled={accountData?.isBlocked}
        onChangeEmail={() => openPopup('changeEmail')}
        onChangePhone={() => openPopup('changePhone')}
        onChangePassword={() => openPopup('changePassword')}
      />
    ),
    changeEmail: (props) => (
      <ChangeEmailPopup
        {...props}
        onConfirmPassword={handleConfirmPassword}
        onSendCode={handleSendUpdateEmailAddressCode}
        onVerifyCode={handleUpdateEmailAddress}
      />
    ),
    changePhone: (props) => (
      <ChangePhonePopup
        {...props}
        phoneNumberFormat={country || undefined}
        onConfirmPassword={handleConfirmPassword}
        onSendOldPhoneCode={handleSendOldPhoneNumberCode}
        onSendCode={handleSendUpdatePhoneNumberCode}
        onVerifyNewPhoneCode={handleUpdatePhoneNumber}
        onVerifyOldPhoneCode={handleVerifyOldPhoneNumber}
        oldPhone={accountData?.phone || ''}
      />
    ),
    changePassword: (props) => (
      <ChangePasswordPopup
        {...props}
        onPasswordChange={handlePasswordChange}
        onClose={() => closePopup('changePassword')}
      />
    ),
  });

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleConfirmPassword = async (password: string) => {
    await dispatchWithUnwrap(createPasswordValidationToken(password));
  };

  const handleSendUpdateEmailAddressCode = async (newEmail: string) => {
    await dispatchWithUnwrap(sendUpdateEmailAddressCode(newEmail));
  };

  const handleUpdateEmailAddress = async (code: string) => {
    await dispatchWithUnwrap(updateEmailAddress(code));
  };

  const handleSendUpdatePhoneNumberCode = async (newPhone: string) => {
    await dispatchWithUnwrap(sendUpdatePhoneNumberCode(newPhone));
  };

  const handleUpdatePhoneNumber = async (code: string) => {
    await dispatchWithUnwrap(updatePhoneNumber(code));
  };

  const handlePasswordChange = async (oldPassword: string, newPassword: string) => {
    await dispatchWithUnwrap(updatePassword({ oldPassword, newPassword }));
  };

  const handleVerifyOldPhoneNumber = async (code: string) => {
    await dispatchWithUnwrap(verifyPhone(code));
  };

  const handleSendOldPhoneNumberCode = async () => {
    await dispatchWithUnwrap(sendPhoneVerificationCode());
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate(AppRoute.SignIn);
  };

  const getApplicationBrowserTitle = () => {
    if (topPopupKey && LOGIN_DETAILS_POPUPS.includes(topPopupKey)) {
      return 'Login Details';
    }

    return applicationsBrowserTitle;
  };

  return (
    <MainLayout
      disableScroll={topPopupKey === 'loginDetails'}
      applicationsBrowserTitle={getApplicationBrowserTitle()}
      contentClassName={layoutContentClassName}
      innerContentClassName={clsx(
        showApplicationHeader && styles.content,
        innerContentClassName,
      )}
      renderElementBeforeContent={() => (
        <AuthorizedHeader
          onLoginDetailsClick={() => openPopup('loginDetails')}
          onLogoutClick={handleLogoutClick}
          isLoading={isLoading}
          hideBorrowerDetails={hideBorrowerDetails}
          renderApplicationHeader={renderApplicationHeader}
          showApplicationHeader={showApplicationHeader}
          onHomeButtonClick={onHomeButtonClick}
        />
      )}
      testModeNotifications={testModeNotifications}
    >
      {children}
      {renderPopups()}
    </MainLayout>
  );
};

export default AuthorizedLayout;
