import { FC, useState } from 'react';
import UnauthorizedLayout from 'layout/UnauthorizedLayout';
import SignInForm from 'components/Auth/forms/SignInForm';
import { ISignInFormContextType } from 'components/Auth/forms/SignInForm/SignInForm';
import { AppRoute } from 'enums/AppRoute';
import {
  getCurrentAccount,
  signIn,
} from 'handlers/authSlice';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { createNotification } from 'handlers/notificationsSlice';

const SignIn: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ISignInFormContextType) => {
    try {
      setIsLoading(true);
      await dispatchWithUnwrap(signIn(values));
      await dispatchWithUnwrap(getCurrentAccount());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      createNotification({ notification: error.message, type: 'error', dispatch });
    }
  };

  return (
    <UnauthorizedLayout
      formTitle="Welcome Back"
      formSubtitle="Please sign in to your account."
      footerText="New User?"
      footerLinkText="Create Account"
      footerLink={AppRoute.CreateAccount}
    >
      <SignInForm
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </UnauthorizedLayout>
  );
};

export default SignIn;
